import React, { useState } from "react";

const ConsumerSupportPage = ({ onProceed }) => {
  const [loading, setLoading] = useState(false);

  const handleProceed = () => {
    setLoading(true);
    return onProceed();
  };

  return (
    <div className="application-checkout-questions fixed-button-section consumer-support-page">
      <h4 className="">
        Need help feeling overwhelmed or vulnerable?
      </h4>

      <div className="border border-gray-200 rounded-lg p-6 mb-2">
        <h3 className="mb-2">Citizens Advice</h3>
        <p className="text-[#636466] mb-2">
          Charity offering free, confidential advice.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-2">
          <div>
            <h4 className="font-semibold mb-2">Adviceline (England)</h4>
            <a href="tel:08001448848" className="text-blue-600 hover:text-blue-800">
              0800 144 8848
            </a>
          </div>
          <div>
            <h4 className="font-semibold mb-2">Adviceline (England)</h4>
            <a href="tel:08007022020" className="text-blue-600 hover:text-blue-800">
              0800 702 2020
            </a>
          </div>
        </div>

        <p className="text-[#636466] mb-2">
          If you have a hearing or speech impairment.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div>
            <h4 className="font-semibold mb-2">Relay (England)</h4>
            <a href="tel:1800108001448848" className="text-blue-600 hover:text-blue-800">
              18001 0800 144 8848
            </a>
          </div>
          <div>
            <h4 className="font-semibold mb-2">Relay (England)</h4>
            <a href="tel:1800108007022020" className="text-blue-600 hover:text-blue-800">
              18001 0800 702 2020
            </a>
          </div>
        </div>
      </div>

      <div className="bg-white border border-gray-200 rounded-lg p-6 mb-2">
        <h3 className="font-semibold mb-2">Money helper</h3>
        <p className="text-[#636466] mb-2">
          Government backed free and impartial help with money & pensions
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-2">
          <div>
            <h4 className="font-semibold mb-2">English</h4>
            <a href="tel:08001387777" className="text-blue-600 hover:text-blue-800">
              0800 138 7777
            </a>
          </div>
          <div>
            <h4 className="font-semibold mb-2">Welsh</h4>
            <a href="tel:08001380555" className="text-blue-600 hover:text-blue-800">
              0800 138 0555
            </a>
          </div>
        </div>

        <p className="text-[#636466] mb-2">
          If you have a hearing or speech impairment.
        </p>

        <div>
          <h4 className="font-semibold mb-2">Type talk</h4>
          <a href="tel:1800108009154622" className="text-blue-600 hover:text-blue-800">
            18001 0800 915 4622
          </a>
        </div>
      </div>

      <div className="flex justify-center">
        <button
          onClick={handleProceed}
          className="light-blue w-100"
          disabled={loading}
        >
          {loading ? "Loading..." : "Continue"}
        </button>
      </div>
    </div>
  );
};

export default ConsumerSupportPage;
