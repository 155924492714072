import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { updateTerms, getPolicies } from "services";
import TermsAccordion from "components/terms/TermsAccordion";

const LegalStuffPage = (props) => {
  const [ completedTerms, setCompletedTerms ] = useState([]);
  const [ openTerms, setOpenTerms ] = useState("t_and_c");
  const [ policies, setPolicies ] = useState({});

  const user = useSelector(state => state?.auth?.user);

  useEffect(() => {
    getPolicies({ dealer: props.dealer?.id })
      .then(res => {
        setPolicies(res);
      });
  }, [props.dealer]);

  const scrollToTop = () => {
    const termsPage = document.getElementById('terms-page');

    if (termsPage) {
      setTimeout(() => {
        termsPage.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }, 10);
    }
  }

  const handleCompleteSection = (section) => {
    let localCompletedTerms = [...completedTerms];
    localCompletedTerms.push(section);
    setCompletedTerms(localCompletedTerms);

    if (section === "t_and_c") {
      setOpenTerms("privacy");
      scrollToTop();
    } else if (section === "privacy") {
      if (policies.idd) {
        setOpenTerms("initial");
        scrollToTop();
      } else {
        scrollToTop();
        setOpenTerms("commission");
      }
    } else if(section === "initial") {
      setOpenTerms("commission");
      scrollToTop();
    } else {
      updateTerms(user.id)
        .then(res => {
          props.onProceed(res);
        });
    }
  }

  return (
    <div className="guest-page px-3 terms-page" id="terms-page">
      <div className="guest-title">
        The legal stuff
      </div>
      <div className="guest-page-info">
        You’re now about to enter the world of instant pre-approved offers but before we do that we’ve got some important information that we’d like to bring to your attention.
      </div>

      <div className="terms-container">
        <TermsAccordion
          name="t_and_c"
          title="Terms & Conditions"
          info="Here you will understand your rights and responsibilities when using our service so you can engage with our service more confidently and responsibly"
          content={policies.terms}
          open={openTerms === "t_and_c"}
          completed={completedTerms.includes("t_and_c")}
          onCompleted={handleCompleteSection}
          onExpand={name => setOpenTerms(name)}
        />

        <TermsAccordion
          name="privacy"
          title="Privacy Policy"
          info="Our privacy policy provides information about your personal data management, helping you make informed decisions and protect your privacy online"
          content={policies.privacy}
          open={openTerms === "privacy"}
          completed={completedTerms.includes("privacy")}
          onCompleted={handleCompleteSection}
          onExpand={name => setOpenTerms(name)}
        />

        { policies.idd && (
          <TermsAccordion
            name="initial"
            title="Initial Disclosure Document"
            info="In this document the retailer provides you with their details, associated fees, service scope, responsibilities, regulatory compliance, complaints, data protection and termination terms "
            content={policies.idd}
            open={openTerms === "initial"}
            completed={completedTerms.includes("initial")}
            onCompleted={handleCompleteSection}
            onExpand={name => setOpenTerms(name)}
          />
        )}

        <TermsAccordion
          name="commission"
          title="Commission Disclosure Document"
          info="In this document the retailer provides you with details of the commission that they will receive from the lenders that they work with and the mechanics of how it is calculated."
          content={policies.commission}
          open={openTerms === "commission"}
          completed={completedTerms.includes("commission")}
          onCompleted={handleCompleteSection}
          onExpand={name => setOpenTerms(name)}
        />
      </div>
    </div>
  );
}

export default LegalStuffPage;
