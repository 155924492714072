import React, { useState } from "react";

import { complianceQuestionAudit } from "services/applications";
import ComplianceQuestionPage from "./ComplianceQuestionPage";

const ComplianceQuestionsPage = (props) => {
  console.log('props', props);
  const [ loading, setLoading ] = useState(false);
  const [ step, setStep ] = useState(0);

  const getQuestion = () => {
    return props.questions[step];
  }

  const isLast = () => {
    return (step === (props.questions.length - 1));
  }

  const handleProceed = () => {
    complianceQuestionAudit(props.quote?.quoteGuid, getQuestion()?.question)
      .then(res => {
        let totalQuestions = props.questions.length;
        if ((step + 1) >= totalQuestions) {
          setLoading(true);
          return props.onProceed();
        }

        setStep(step + 1);
      })
      .catch(err => {
        console.log('err', err);
      });
  }

  return (
    <ComplianceQuestionPage { ...getQuestion() } onProceed={handleProceed} loading={loading} isLast={false} />
  );
}

export default ComplianceQuestionsPage;
