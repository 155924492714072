import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  getApplicationStatus,
  getOffer,
  getOtherLenders,
  hasPxRequest,
  updateQuote,
} from "services";
import { setCurrentQuote, clearCurrentQuote } from "store/actions/general";
import { useDataLoading } from "components/loader/LoaderHook";
import BenefitsList from "components/BenefitsList";
import DecisionPage from "pages/auth/offers/checkout/decision";
import FullQuoteInfo from "components/offers/FullQuoteInfo";
import Introduction from "components/offers/Introduction";
import LeftHandQuoteDetails from "components/LeftHandQuoteDetails";
import Lender from "components/offers/Lender";
import Loader from "components/Loader";
import OfferFilter from "components/offers/OfferFilter";
import OfferSort from "components/offers/OfferSort";
import PXInfo from "components/offers/PXInfo";
import Quote from "components/offers/Quote";
import QuoteBuilder from "components/offers/QuoteBuilder";
import VehicleInformation from "components/offers/VehicleInformation";

import { ReactComponent as Logo } from "assets/images/logo.svg";

const OfferDetailsPage = (props) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { loading, setLoading } = useDataLoading(true);

  const [application, setApplication] = useState({});
  const [editQuote, setEditQuote] = useState({});
  const [decisionQuote, setDecisionQuote] = useState({
    lender: null,
    quote: null,
  });
  const [filteredOffers, setFilteredOffers] = useState(null);
  const [isPxRequest, setIsPxRequest] = useState(false);
  const [offer, setOffer] = useState(null);
  const [offerFilter, setOfferFilter] = useState("all");
  const [offerSort, setOfferSort] = useState("monthly");
  const [originalOffer, setOriginalOffer] = useState(null);
  const [otherLenders, setOtherLenders] = useState([]);
  const [pxInfoOpen, setPxInfoOpen] = useState(true);
  const [quoteChanged, setQuoteChanged] = useState(false);
  const [reload, setReload] = useState(false);
  const [selectedLender, setSelectedLender] = useState(null);
  const [selectedQuote, setSelectedQuote] = useState(null);
  const [showDecisionPage, setShowDecisionPage] = useState(false);
  const [showRecalcMsg, setShowRecalcMsg] = useState(false);
  const [userUnderstand, setUserUnderstand] = useState(true);

  const dispatch = useDispatch();

  const user = useSelector((state) => state?.auth?.user);
  const currentQuote = useSelector((state) => state?.general?.currentQuote);

  const path = window.location.href;

  const regExp = new RegExp("/confirm$");
  const shouldConfirm = regExp.test(path);

  useEffect(() => {
    if (id === currentQuote.applicationGuid && !shouldConfirm) {
      setOffer(currentQuote);
      setLoading(false);

      filterAndSortOffers(currentQuote);

      getOtherLenders(id).then((res) => {
        setOtherLenders(res);
      });
    } else {
      getOfferData();
    }
  }, []);

  useEffect(() => {
    if (offer) {
      filterAndSortOffers(offer);
    }
  }, [offerFilter, offerSort]);

  const filterAndSortOffers = (offers) => {
    let localOffers = [...offers.lenderList].filter(
      (offer) => offer.quoteList.length > 0
    );

    localOffers = localOffers.flatMap((lender) => {
      return lender.quoteList.map((quote) => {
        return {
          ...lender,
          quoteList: [quote],
        };
      });
    });

    if (offerFilter !== "all") {
      localOffers = localOffers.filter((lender) => {
        return lender.quoteList[0].productType === offerFilter;
      });
    }

    localOffers = localOffers.sort((lenderA, lenderB) => {
      if (offerSort === "monthly") {
        return lenderA.quoteList[0].payments - lenderB.quoteList[0].payments;
      } else if (offerSort === "apr") {
        return lenderA.quoteList[0].apr - lenderB.quoteList[0].apr;
      } else if (offerSort === "total") {
        return (
          lenderA.quoteList[0].totalPayable - lenderB.quoteList[0].totalPayable
        );
      }
    });

    setFilteredOffers({
      ...offers,
      lenderList: localOffers,
    });
  };

  const getQuoteDetails = (application, quote) => {
    var quoteDetail;
    var lender;

    let matchingQuoteStatus =
      application.quoteStatus === ""
        ? "Submitted To Lender"
        : application.quoteStatus;

    quote.lenderList.map((l) => {
      l.quoteList.map((q) => {
        if (q.status === matchingQuoteStatus) {
          quoteDetail = q;
          lender = l;
        }
      });
    });

    setDecisionQuote({
      quote: quoteDetail,
      lender,
    });
  };

  const getOfferData = () => {
    setLoading(true);

    getApplicationStatus(id).then((res) => {
      setApplication(res);

      const localApplication = res;

      getOffer(id, shouldConfirm).then((res) => {
        if (res?.error) {
          navigate("/");
        } else {
          if (
            ["Approved", "Declined", "Referred", "Pending"].includes(
              localApplication.quoteStatus
            ) ||
            localApplication.submissionStatus === "Submitted"
          ) {
            setShowDecisionPage(true);
          } else {
            setShowDecisionPage(false);
          }

          getQuoteDetails(localApplication, res);

          setOffer(res);
          setOriginalOffer(res);
          setLoading(false);
          setQuoteChanged(false);

          filterAndSortOffers(res);
          dispatch(setCurrentQuote(res));

          getOtherLenders(id).then((res) => {
            setOtherLenders(res);
          });

          hasPxRequest(res?.applicationGuid).then((res) => {
            setIsPxRequest(res);
          });
        }
      });
    });
  };

  const handleQuoteChange = (quote) => {
    setEditQuote(quote);
  };

  const handleQuoteUpdate = (res) => {
    setOffer(res);
    filterAndSortOffers(res);
    dispatch(setCurrentQuote(res));

    getOtherLenders(id).then((res) => {
      setOtherLenders(res);
    });

    hasPxRequest(id).then((res) => {
      setIsPxRequest(res);
    });
  };

  const handleUpdateQuote = () => {
    setLoading(true);

    updateQuote(offer.applicationGuid, editQuote)
      .then((res) => {
        handleQuoteUpdate(res);
        setQuoteChanged(false);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOnSelect = (quote, lender) => {
    setSelectedLender(lender);
    setSelectedQuote(quote);
  };

  const handleOnClose = () => {
    setSelectedLender(null);
    setSelectedQuote(null);
  };

  const handleQuoteReset = () => {
    setOffer(originalOffer);
    setQuoteChanged(false);
    setShowRecalcMsg(false);
    setReload(!reload);
  };

  const scrollToQuotesTop = () => {
    let elm = document.getElementById("quote-filters-bar");

    if (elm) {
      elm.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const handleOffersScroll = (e) => {
    setPxInfoOpen(e.target.scrollTop < 20);
  };

  return (
    <>
      {showDecisionPage ? (
        <Container fluid className="quote-checkout-page">
          <Row className="h-100">
            <Col xs={12} lg={6} className="left-hand-content">
              <div className="d-flex flex-column justify-content-evenly">
                <LeftHandQuoteDetails
                  application={application}
                  quote={decisionQuote?.quote}
                  lender={decisionQuote?.lender}
                  hideButtons
                />
              </div>
            </Col>
            <Col xs={12} lg={6} className="right-hand-content">
              <DecisionPage
                application={application}
                quote={decisionQuote?.quote}
                lender={decisionQuote?.lender}
                decision={application?.quoteStatus?.toLowerCase()}
                onDeclineClick={(e) => setShowDecisionPage(false)}
              />
            </Col>
          </Row>
        </Container>
      ) : (
        <Container
          fluid
          className={`offer-details-page ${loading ? "container-loading" : ""}`}
        >
          <Row>
            <Col
              xs={12}
              xl={loading ? 6 : 3}
              lg={loading ? 6 : 4}
              className={`left-hand-content ${
                loading ? "content-loading" : ""
              }`}
            >
              {loading ? (
                <>
                  <div className="logo-container">
                    <Logo className="content-logo" />
                  </div>

                  <BenefitsList />
                </>
              ) : (
                <div className="d-flex flex-column justify-content-between">
                  <Introduction name={user.first_name} mobile />

                  {offer.includeEquity && (
                    <div>
                      <PXInfo mobile />
                    </div>
                  )}

                  <VehicleInformation offer={offer} mobile={false} />
                  <VehicleInformation offer={offer} mobile />

                  <QuoteBuilder
                    offer={offer}
                    onUpdate={handleUpdateQuote}
                    onQuoteChange={handleQuoteChange}
                    onPxUpdate={() => setQuoteChanged("px")}
                    onQuoteReset={handleQuoteReset}
                    onUnderstand={setUserUnderstand}
                    setLoading={setLoading}
                    setQuoteChanged={setQuoteChanged}
                    setShowRecalcMsg={setShowRecalcMsg}
                    showRecalcMsg={showRecalcMsg}
                    quoteChanged={quoteChanged}
                    reload={reload}
                  />
                </div>
              )}
            </Col>
            <Col
              xs={12}
              xl={loading ? 6 : 9}
              lg={loading ? 6 : 8}
              className="right-hand-content"
            >
              {loading ? (
                <div className="full-recalc-loading-container">
                  <div className="recalc-loading-content">
                    <div className="loading-title">Fetching your offers</div>

                    <div className="loading-animation">
                      <Loader />
                    </div>

                    <p className="text-center loading-info-text">
                      We’re matching you with lenders and preparing your offers,
                      this could take up to 30 seconds
                    </p>
                  </div>
                </div>
              ) : (
                <>
                  <Introduction name={user.first_name} mobile={false} />

                  {offer.includeEquity && (
                    <div>
                      <PXInfo open={pxInfoOpen} />
                    </div>
                  )}

                  <Row id="quote-filters-bar" className="quote-filter-bar mb-4">
                    <Col>
                      <OfferFilter
                        selected={offerFilter}
                        onChange={(val) => setOfferFilter(val)}
                      />
                    </Col>
                    <Col className="discont">
                      <OfferSort
                        selected={offerSort}
                        onChange={(val) => setOfferSort(val)}
                      />
                    </Col>
                  </Row>

                  <div
                    className={`offers-container ${
                      quoteChanged ? "quote-changed-container" : ""
                    }`}
                    onScroll={handleOffersScroll}
                  >
                    {quoteChanged !== false && (
                      <div className="quote-changed-overlay">
                        {userUnderstand && (
                          <div className="quote-changed-content">
                            <p className="mb-3">
                              You can continue to make changes until you’re
                              happy then you will need to recalculate the
                              quotes.
                            </p>

                            <div className="quote-actions mt-3">
                              <button
                                className="green"
                                onClick={handleUpdateQuote}
                                disabled={false}
                              >
                                Recalculate monthly payment
                              </button>
                            </div>
                          </div>
                        )}
                      </div>
                    )}

                    {selectedQuote ? (
                      <FullQuoteInfo
                        application={offer}
                        lender={selectedLender}
                        quote={selectedQuote}
                        onClose={handleOnClose}
                      />
                    ) : (
                      <>
                        {filteredOffers && (
                          <>
                            {filteredOffers.lenderList.map((lender) => (
                              <React.Fragment
                                key={`${lender.quoteList[0].quoteGuid}`}
                              >
                                {lender.quoteList.length > 0 && (
                                  <>
                                    {lender.quoteList.map((lenderQuote) => (
                                      <Quote
                                        application={offer}
                                        quote={lenderQuote}
                                        lender={lender}
                                        onSelect={handleOnSelect}
                                        pxRequest={isPxRequest}
                                        onPxRequest={setIsPxRequest}
                                        key={lenderQuote.quoteGuid}
                                      />
                                    ))}
                                  </>
                                )}
                              </React.Fragment>
                            ))}
                          </>
                        )}

                        {otherLenders.length > 0 && (
                          <>
                            <p className="warning-text">
                              You may also be eligible for offers from these
                              lenders. Please either increase your deposit or
                              adjust your loan term to see if you are eligible
                              for any of these offers.
                            </p>

                            <div className="grid grid-cols-2 md:grid-cols-4 2xl:grid-cols-5 lender-grid">
                              {otherLenders.map((lender) => (
                                <Lender
                                  lender={lender}
                                  key={lender.lenderGuid}
                                />
                              ))}
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>

                  <QuoteBuilder
                    offer={offer}
                    onUpdate={handleUpdateQuote}
                    onQuoteChange={handleQuoteChange}
                    onPxUpdate={() => setQuoteChanged("px")}
                    onQuoteReset={handleQuoteReset}
                    onUnderstand={setUserUnderstand}
                    setLoading={setLoading}
                    setQuoteChanged={setQuoteChanged}
                    mobile
                    quoteChanged={quoteChanged}
                    setShowRecalcMsg={setShowRecalcMsg}
                    showRecalcMsg={showRecalcMsg}
                    reload={reload}
                    onClose={scrollToQuotesTop}
                  />
                </>
              )}
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};

export default OfferDetailsPage;
