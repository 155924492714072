import React, { useState } from "react";

import { complianceQuestionAudit } from "services/applications";
import { formatCurrency } from "utils/currency";
import CommissionDisclosureModal from "components/modals/CommissionDisclosureModal";
import DealerCommissionModal from "components/modals/DealerCommissionModal";

const DealerCommissionPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [showDealerCommissionModal, setShowDealerCommissionModal] = useState(false);
  const [showCommissionModal, setShowCommissionModal] = useState(false);

  const question = `I confirm that I have read the Commission Disclosure Document and understand the nature and existence of the commission of the ${formatCurrency(props.quote?.dealerCommission, 0)}. Learn how this is calculated that will be paid to the dealer by ${props?.application?.lender?.lenderName} if I proceed.`;

  const onProceed = () => {
    setLoading(true);
    complianceQuestionAudit(props.quote?.quoteGuid, question)
      .then(res => {
        return props.onProceed();
      })
      .catch(err => {
        console.log('err', err);
      });
  }

  return (
    <div className="application-checkout-questions fixed-button-section">
      <h4>Dealer Commission</h4>

      <p>
        I confirm that I have read the <a href="#" onClick={e => setShowCommissionModal(true)}><span>Commission Disclosure Document</span></a> and
        understand the nature and existence of the commission of the {formatCurrency(props.quote?.dealerCommission, 0)}. <a href="#" onClick={e => setShowDealerCommissionModal(true)}>
          <span>Learn how this is calculated</span></a> that will be paid to the
        dealer by {props?.application?.lender?.lenderName} if I proceed.
      </p>

      <button onClick={onProceed} className="light-blue fixed-checkout-button" disabled={loading}>
        {loading ? "Submitting..." : "I consent"}
      </button>

      <DealerCommissionModal
        show={showDealerCommissionModal}
        onHide={e => setShowDealerCommissionModal(false)}
        quote={props?.quote}
        lender={props?.lender}
      />

      <CommissionDisclosureModal
        show={showCommissionModal}
        onHide={e => setShowCommissionModal(false)}
        content=""
      />
    </div>
  );
}

export default DealerCommissionPage;
