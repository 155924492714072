import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

import { formatCurrency } from "utils/currency";
import { getPolicies } from "services";
import { ReactComponent as Logo } from "assets/images/logo.svg";

const CommissionDisclosureModal = (props) => {
  const [content, setContent] = useState("");

  useEffect(() => {
    getPolicies()
      .then(res => {
        setContent(res.commission);
      });
  }, []);

  if (!props.show) {
    return null;
  }

  return (
    <>
      <Modal
        centered
        show={props.show}
        onHide={props.onHide}
        className="product-info-modal blurred commission-statement-modal"
      >
        <Modal.Header closeButton>
          <Logo />
        </Modal.Header>
        <Modal.Body>
          <p>Data classification: Confidential</p>

          <div
            dangerouslySetInnerHTML={{ __html: content }}
            className="py-2 mt-4 overflow-y-auto"
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CommissionDisclosureModal;
